import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyC3S-GJysAD_52uY9tibb-LJiZePO2IbrU',
  authDomain: 'personal-projects-852c2.firebaseapp.com',
  databaseURL: 'https://personal-projects-852c2.firebaseio.com',
  projectId: 'personal-projects-852c2',
  storageBucket: 'personal-projects-852c2.appspot.com',
  messagingSenderId: '346326306438',
  appId: '1:346326306438:web:b8623cb08c9536b47e4b07',
  measurementId: 'G-JJNNPHKSYX'
};

initializeApp(firebaseConfig);
