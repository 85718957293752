import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Table,
  LinearProgress
} from '@mui/joy';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { format, parseISO, add, isTomorrow } from 'date-fns';
import { useEffect, useState } from 'react';
import SpaceTooltip from './SpaceTooltip';

const MoonPhases = ({ location: { latitude, longitude } }) => {
  const [sunAndMoonDataToday, setSunMoonDataToday] = useState();
  const [sunAndMoonDataTomorrow, setSunMoonDataTomorrow] = useState();
  const [moonPhaseData, setMoonPhaseData] = useState();

  useEffect(() => {
    const sunAndMoonData = async date => {
      const sunAndMoonData = httpsCallable(getFunctions(), 'sunAndMoonData');
      const astroData = await sunAndMoonData({
        date,
        latitude,
        longitude,
        timezone: new Date().getTimezoneOffset() / -60
      });

      return astroData.data;
    };

    const fetchMoonPhaseData = async () => {
      const sunAndMoonDataToday = await sunAndMoonData(
        format(new Date(), 'yyyy-MM-dd')
      );
      setSunMoonDataToday(sunAndMoonDataToday);
      const sunAndMoonDataTomorrow = await sunAndMoonData(
        format(add(new Date(), { days: 1 }), 'yyyy-MM-dd')
      );
      setSunMoonDataTomorrow(sunAndMoonDataTomorrow);
    };

    fetchMoonPhaseData();
  }, [latitude, longitude]);

  useEffect(() => {
    const fetchMoonPhases = async () => {
      const moonPhaseData = httpsCallable(getFunctions(), 'moonPhaseData');
      const moonData = await moonPhaseData({
        date: format(new Date(), 'yyyy-MM-dd')
      });

      setMoonPhaseData(moonData);
    };

    fetchMoonPhases();
  }, []);

  const mapPhenomenon = phen => {
    const mappings = {
      Set: 'Moonset',
      Rise: 'Moonrise'
    };
    return mappings[phen] || phen;
  };

  const MoonStatsForDay = ({ date, sunAndMoonData }) => (
    <>
      <Grid container alignItems="center">
        <Grid xs={12}>
          <Typography level="title-lg">
            {isTomorrow(date) ? 'Tomorrow' : 'Today'} (
            {format(date, 'EEEE, do MMMM yyyy')})
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Typography level="body-md">
            {sunAndMoonData.properties.data.curphase}
          </Typography>
          <Typography level="body-md">
            {sunAndMoonData.properties.data.fracillum} illuminated
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Table>
            <tbody>
              {sunAndMoonData.properties.data.moondata.map(moonData => (
                <tr key={moonData.time}>
                  <td>
                    <SpaceTooltip text={mapPhenomenon(moonData.phen)} />
                  </td>
                  <td>
                    {format(
                      parseISO(`2022-01-01T${moonData.time}:00`),
                      'h:mm aaa'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
    </>
  );

  let cardContent;

  if (!sunAndMoonDataToday || !sunAndMoonDataTomorrow || !moonPhaseData) {
    cardContent = <LinearProgress />;
  } else {
    console.log(sunAndMoonDataToday);
    cardContent = (
      <>
        <MoonStatsForDay
          date={new Date()}
          sunAndMoonData={sunAndMoonDataToday}
        />
        <MoonStatsForDay
          date={add(new Date(), { days: 1 })}
          sunAndMoonData={sunAndMoonDataTomorrow}
        />
        <Typography level="title-lg" my={1}>
          Upcoming phases
        </Typography>
        <Table>
          <tbody>
            {moonPhaseData.data.phasedata.map(phase => {
              const utcDate = `${phase.year}-${String(phase.month).padStart(
                2,
                '0'
              )}-${String(phase.day).padStart(2, '0')}T${phase.time}:00Z`; // Construct UTC date string
              const dateObject = parseISO(utcDate); // Parse the UTC string into a Date object
              return (
                <tr key={utcDate}>
                  <td>
                    <SpaceTooltip text={phase.phase} />
                  </td>
                  <td>{format(dateObject, 'dd MMMM yyyy')}</td>
                  <td>{format(dateObject, 'h:mm aaa')}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <Card color="primary" variant="soft">
      <Typography level="h3">Our Moon 🌝</Typography>
      <Typography level="body-xs">
        The Earth's moon for the next 2 days, and then the next major phases.
      </Typography>
      <Divider />
      <CardContent>{cardContent}</CardContent>
    </Card>
  );
};

export default MoonPhases;
