import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Table,
  LinearProgress
} from '@mui/joy';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { format, parseISO, add, isTomorrow } from 'date-fns';
import { useEffect, useState } from 'react';
import SpaceTooltip from './SpaceTooltip';

const SunTransitTimes = ({ location: { latitude, longitude } }) => {
  const [sunAndMoonDataToday, setSunMoonDataToday] = useState();
  const [sunAndMoonDataTomorrow, setSunMoonDataTomorrow] = useState();

  useEffect(() => {
    const sunAndMoonData = async date => {
      const sunAndMoonData = httpsCallable(getFunctions(), 'sunAndMoonData');
      const astroData = await sunAndMoonData({
        date,
        latitude,
        longitude,
        timezone: new Date().getTimezoneOffset() / -60
      });

      return astroData.data;
    };

    const fetchMoonPhaseData = async () => {
      const sunAndMoonDataToday = await sunAndMoonData(
        format(new Date(), 'yyyy-MM-dd')
      );
      setSunMoonDataToday(sunAndMoonDataToday);
      const sunAndMoonDataTomorrow = await sunAndMoonData(
        format(add(new Date(), { days: 1 }), 'yyyy-MM-dd')
      );
      setSunMoonDataTomorrow(sunAndMoonDataTomorrow);
    };

    fetchMoonPhaseData();
  }, [latitude, longitude]);

  const mapPhenomenon = phen => {
    const mappings = {
      Set: 'Sunset',
      Rise: 'Sunrise'
    };
    return mappings[phen] || phen;
  };

  const SunTransitTimesForDay = ({ date, sunAndMoonData }) => (
    <>
      <Grid container alignItems="center">
        <Grid xs={12}>
          <Typography level="title-lg" gutterBottom>
            {isTomorrow(date) ? 'Tomorrow' : 'Today'} (
            {format(date, 'EEEE, do MMMM yyyy')})
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Table>
            <tbody>
              {sunAndMoonData.properties.data.sundata.map(sunData => (
                <tr key={sunData.time}>
                  <td>
                    <SpaceTooltip text={mapPhenomenon(sunData.phen)} />
                  </td>
                  <td>
                    {format(
                      parseISO(`2022-01-01T${sunData.time}:00`),
                      'h:mm aaa'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
    </>
  );

  let cardContent;

  if (!sunAndMoonDataToday || !sunAndMoonDataTomorrow) {
    cardContent = <LinearProgress />;
  } else {
    console.log(sunAndMoonDataToday);
    cardContent = (
      <>
        <SunTransitTimesForDay
          date={new Date()}
          sunAndMoonData={sunAndMoonDataToday}
        />
        <SunTransitTimesForDay
          date={add(new Date(), { days: 1 })}
          sunAndMoonData={sunAndMoonDataTomorrow}
        />
      </>
    );
  }

  return (
    <Card color="primary" variant="soft">
      <Typography level="h3">Our Sun 🌞</Typography>
      <Typography level="body-xs">
        Transit times for our sun today and tomorrow.
      </Typography>
      <Divider />
      <CardContent>{cardContent}</CardContent>
    </Card>
  );
};

export default SunTransitTimes;
