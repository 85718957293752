import {
  Typography,
  Card,
  CardContent,
  Divider,
  Table,
  LinearProgress
} from '@mui/joy';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import SpaceTooltip from './SpaceTooltip';

const EarthSeasons = () => {
  const [earthSeasonsData, setEarthSeasonsData] = useState();

  useEffect(() => {
    const fetchEarthSeasons = async () => {
      const earthSeasons = httpsCallable(getFunctions(), 'earthSeasons');
      const earthSeasonsData = await earthSeasons({
        timezone: new Date().getTimezoneOffset() / -60,
        year: format(new Date(), 'yyyy')
      });

      setEarthSeasonsData(earthSeasonsData.data);
    };

    fetchEarthSeasons();
  }, []);

  let cardContent;
  if (!earthSeasonsData) {
    cardContent = <LinearProgress />;
  } else {
    cardContent = (
      <Table sx={{ '& tbody td:nth-of-type(1)': { width: '33%' } }}>
        <tbody>
          {earthSeasonsData.data.map(phase => {
            const utcDate = `${phase.year}-${String(phase.month).padStart(
              2,
              '0'
            )}-${String(phase.day).padStart(2, '0')}T${phase.time}:00Z`; // Construct UTC date string
            const dateObject = parseISO(utcDate); // Parse the UTC string into a Date object
            return (
              <tr key={utcDate}>
                <td>
                  <SpaceTooltip text={phase.phenom} />
                </td>
                <td>{format(dateObject, 'dd MMMM yyyy @ h:mm aaa')}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <Card color="primary" variant="soft">
      <Typography level="h3">Earth's seasons 🌧️🍃🌞</Typography>
      <Typography level="body-xs">
        The dates and times for the equinoxes, solstices, and apsides
        (perihelion and aphelion) of Earth's orbit in 2024.
      </Typography>
      <Divider />
      <CardContent>{cardContent}</CardContent>
    </Card>
  );
};

export default EarthSeasons;
