import {
  Typography,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemDecorator,
  LinearProgress
} from '@mui/joy';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

const SolarEclipses = () => {
  const [solarEclipsesData, setSolarEclipsesData] = useState();

  useEffect(() => {
    const fetchSolarEclipses = async () => {
      const findSolarEclipses = httpsCallable(getFunctions(), 'solarEclipses');
      const earthSeasonsData = await findSolarEclipses({
        year: format(new Date(), 'yyyy')
      });

      setSolarEclipsesData(earthSeasonsData.data);
    };

    fetchSolarEclipses();
  }, []);

  let cardContent;
  if (!solarEclipsesData) {
    cardContent = <LinearProgress />;
  } else {
    cardContent = (
      <List>
        {solarEclipsesData.eclipses_in_year.map((eclipse, i) => (
          <ListItem key={i}>
            <ListItemDecorator>☀️</ListItemDecorator>
            {eclipse.event}
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <Card color="primary" variant="soft">
      <Typography level="h3">Solar eclipses</Typography>
      <Typography level="body-xs">
        Solar eclipses for {new Date().getFullYear()}
      </Typography>
      <Divider />
      <CardContent>{cardContent}</CardContent>
    </Card>
  );
};

export default SolarEclipses;
