import { Tooltip, Typography } from '@mui/joy';
import definitions from '../modules/definitions.json';

const SpaceTooltip = ({ text }) => (
  <Tooltip
    title={definitions[text]}
    enterTouchDelay={0}
    leaveTouchDelay={11000}
    color="primary"
  >
    <Typography>{text}</Typography>
  </Tooltip>
);

export default SpaceTooltip;
