import {
  CssBaseline,
  Typography,
  Grid,
  Card,
  Alert,
  Stack,
  Box,
  extendTheme
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import Lottie from 'lottie-react';
import EarthSeasons from './components/EarthSeasons';
import loader from './lottie/rocket.json';
import background from './images/space.jpeg';
import { useEffect, useState } from 'react';
import MoonPhases from './components/MoonPhases';
import SolarEclipses from './components/SolarEclipses';
import SunTransitTimes from './components/SunTransitTimes';

const App = () => {
  const [location, setLocation] = useState();
  const [locationError, setLocationError] = useState(false);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async position => {
            console.log('position', position.coords);
            setLocation(position.coords);
          },
          () => {
            console.error('Location access denied by user.');
            setLocationError(true);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setLocationError(true);
      }
    };

    getUserLocation();
  }, []);

  let dashboard;

  const timezoneDisplay = () => {
    const date = new Date();
    const timezoneOffsetInHours = -date.getTimezoneOffset() / 60;
    const timezoneName = new Intl.DateTimeFormat('en-US', {
      timeZoneName: 'long'
    }).format(date);

    return `${timezoneName.split(', ').pop()} (GMT${
      timezoneOffsetInHours > 0 ? '+' : ''
    }${timezoneOffsetInHours})`;
  };

  if (locationError) {
    dashboard = (
      <Grid
        container
        justifyContent="center"
        height="100vh"
        alignItems="center"
        p={1}
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover'
        }}
      >
        <Grid md={6}>
          <Alert severity="warning">
            <Stack>
              <Typography level="title-lg" textAlign="center" mb={2}>
                Location Sharing Required
              </Typography>
              <Typography level="body-md" textAlign="center">
                To provide accurate moon rise and set times, please enable
                location sharing in your browser settings, then reload the page.
              </Typography>
            </Stack>
          </Alert>
        </Grid>
      </Grid>
    );
  } else if (!location) {
    dashboard = (
      <Box width={333}>
        <Lottie animationData={loader} />
      </Box>
    );
  } else {
    dashboard = (
      <>
        <Grid xs={12} justifyContent="center" display="flex">
          <Card
            color="primary"
            variant="soft"
            sx={{ mb: 8, maxWidth: 555, textAlign: 'center', px: 8, py: 3 }}
          >
            <Typography level="h3">Space 🚀</Typography>
            <Typography level="body-md">
              Discover the cosmos as seen from Earth.
            </Typography>
            <Typography level="body-xs">
              All times and dates are tailored to your local timezone.
            </Typography>
            <Typography level="body-xs">{timezoneDisplay()}</Typography>
          </Card>
        </Grid>
        <Grid md={5}>
          <Stack gap={8}>
            <MoonPhases location={location} />
            <EarthSeasons />
          </Stack>
        </Grid>
        <Grid md={6}>
          <Stack gap={8}>
            <SunTransitTimes location={location} />
            <SolarEclipses />
          </Stack>
        </Grid>
      </>
    );
  }

  const theme = extendTheme({
    fontFamily: {
      display: 'Space Grotesk',
      body: 'Space Grotesk'
    }
  });

  return (
    <CssVarsProvider defaultMode="system" theme={theme}>
      <CssBaseline />
      <Box
        sx={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}
        minHeight="100vh"
        padding={2}
        py={6}
      >
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          {dashboard}
        </Grid>
      </Box>
    </CssVarsProvider>
  );
};

export default App;
